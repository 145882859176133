import React from 'react';

import {graphql} from 'gatsby';
import {Box, LayoutGrid, StyledRichContent, SEOWithQuery} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';

import {PrivacyPolicyPageQuery} from 'graphql-types';

const {P0, P2} = Typography;

export const query = graphql`
  query PrivacyPolicyPage {
    doc: sanityPublicPrivacyPolicy(_id: {eq: "publicPrivacyPolicy"}) {
      effectiveDate(formatString: "MMM D, YYYY")
      content: _rawContent
      copyright
    }
  }
`;

type PrivacyPageProps = {
  data: PrivacyPolicyPageQuery;
};

const PrivacyPage: React.FC<PrivacyPageProps> = ({data}) => {
  return (
    <Box backgroundColor="darkGreen">
      <LayoutGrid>
        <Box gridColumn="1/end">
          <Box textAlign="center" pt="83px" pb="43px" mb="32px">
            <Box width="150px" mb="34px" mx="auto">
              <Icon aria-label={copy.privacyPolicy.ariaLabels.logo} icon={Icons.logo} color="cream" />
            </Box>
            <P0 color="cream" mb="21px">
              {copy.privacyPolicy.title}
            </P0>
            {!!data.doc?.effectiveDate && (
              <P0 color="cream" mb="21px">
                {copy.privacyPolicy.effectiveDateLabel}: {data.doc.effectiveDate}
              </P0>
            )}
          </Box>
          <StyledRichContent blocks={data.doc?.content} />
          {!!data.doc?.copyright && (
            <>
              <Box as="hr" my="24px" />
              <P2 color="cream" textAlign="center" mb="40px">
                {data.doc.copyright}
              </P2>
            </>
          )}
        </Box>
      </LayoutGrid>
      <SEOWithQuery title={copy.seo.privacyTitle} noIndex />
    </Box>
  );
};

export default PrivacyPage;
